import Raven from "raven-js";
import configs from "./utils/configs";
import { getCookieToken } from "./utils/get-auth-user";
import * as gtag from "./gtag";

export default async function registerTelemetry(trackedPage, trackedTitle) {
  const sentryDsn = configs.SENTRY_DSN;
  const gaTrackingId = configs.GA_TRACKING_ID;

  if (sentryDsn) {
    Raven.config(sentryDsn).install();
  }

  if (gaTrackingId) {
    // insert the ga tracking id with a cookie consent data attribute
    gtag.initialize(gaTrackingId, trackedPage, trackedTitle);
  } else {
  }
}

export function registerUserAttendedThePresentation(user_id) {
  const url = configs["CUSTOM_API_URL"] + "/register-user-attended-presentation";

  const cookie = getCookieToken();

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie}`,
      Accept: "application/json"
    },
    body: JSON.stringify({
      user_id: user_id,
      url: window.location.pathname
    })
  }).catch(() => {});
}
